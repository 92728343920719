import { apiService, ENDPOINTS } from 'api';
import { events } from 'app/analytics';

export const getOrganization = async () => {
  try {
    const data = await apiService.callApi({
      endpoint: ENDPOINTS.ORGANIZATIONS,
      errorEvent: events.GET_ORGANIZATIONS_ERROR,
    });
    return data?.organization;
  } catch (err) {
    return err;
  }
};

export const getOrganizationEnvMembers = async () => {
  try {
    const envMembers = await apiService.callApi({
      endpoint: ENDPOINTS.ENV_USERS,
      errorEvent: events.GET_ORG_ENV_MEMBERS_ERROR,
    });
    return envMembers;
  } catch (err) {
    return err;
  }
};

export const updateOrganization = async (reqData) => {
  return await apiService.callApi({
    endpoint: ENDPOINTS.ORGANIZATIONS,
    method: 'patch',
    data: reqData,
    errorEvent: events.UPDATE_ORG_ERROR,
  });
};
