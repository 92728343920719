import { apiService, ENDPOINTS } from 'api';
import { events } from 'app/analytics';

export const getUser = async () => {
  try {
    const data = await apiService.callApi({
      endpoint: ENDPOINTS.USERS,
      errorEvent: events.GET_USER_ERROR,
    });
    return data?.user;
  } catch (err) {
    return err;
  }
};

export const disconnectGitProvider = async (provider) => {
  try {
    const data = await apiService.callApi({
      endpoint: ENDPOINTS.DISCONNECT_GIT_PROVIDER,
      method: 'patch',
      data: { git_provider: provider },
      errorEvent: events.DISCONNECT_GIT_PROVIDER_ERROR,
    });

    return data?.user;
  } catch (err) {
    return err;
  }
};

export const updateUser = async (reqData) => {
  return await apiService.callApi({
    endpoint: ENDPOINTS.USERS,
    method: 'patch',
    data: reqData,
    errorEvent: events.UPDATE_USER_ERROR,
  });
};
