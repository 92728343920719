export const EditingMenu = ({ onEdit, onSetDefault, onDelete }) => {
  const buttonsEditing = [
    {
      label: 'Edit',
      action: onEdit,
    },
    {
      label: 'Set as default',
      action: onSetDefault,
    },
    {
      label: 'Delete',
      action: onDelete,
    },
  ];

  return (
    <div className="absolute w-32 rounded-md shadow-lg bg-white z-50 p-1 overflow-visible">
      <div className="flex flex-col">
        {buttonsEditing.map(({ label, action }) => (
          <div className="w-full hover:bg-gray-100 rounded-md">
            <button
              style={{ width: 100 }}
              key={`editing-menu-${label.toLowerCase().replace(' ', '-')}`}
              onClick={action}
              className="px-2 py-2 text-xs text-gray-700 text-right"
            >
              {label}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};
