import { useState, useEffect } from 'react';
import { TextInput } from '../input/text';
import { Button } from '../../button/button';

export const EnvironmentForm = ({
  environment,
  onSave,
  onCancel,
  envName,
  isEditMode = false,
}) => {
  const [label, setLabel] = useState(environment?.label || '');
  const [value, setValue] = useState(environment?.value || '');

  useEffect(() => {
    if (isEditMode && environment) {
      setLabel(environment.label);
      setValue(environment.value);
    }
  }, [isEditMode, environment]);

  const handleSave = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (label && value) {
      setLabel('');
      setValue('');
      onSave({ label, value });
    }
  };

  const handleLabelChange = (fieldName, newLabel, valid) => {
    setLabel(newLabel);
  };

  const handleValueChange = (fieldName, newValue, valid) => {
    setValue(newValue);
  };

  return (
    <div className={`w-full ${isEditMode ? 'h-full p-4' : 'mb-4'}`}>
      <div className="flex flex-col space-y-2">
        <div className="flex space-x-2">
          <div className="flex-1">
            <span className="block text-sm font-medium mb-1 text-gray-300">
              {isEditMode ? 'Environment name' : 'New environment name'}
            </span>
            <TextInput
              id="environmentName"
              value={label}
              onChange={handleLabelChange}
              name={`${envName}.label`}
              placeholder="Enter environment name"
              className="w-full bg-gray-800 text-white"
            />
          </div>
          <div className="flex-[2]">
            <span className="block text-sm font-medium mb-1 text-gray-300">
              Environment URL
            </span>
            <TextInput
              id="environmentURL"
              value={value}
              onChange={handleValueChange}
              name={`${envName}.value`}
              placeholder="Enter environment URL"
              className="w-full bg-gray-800 text-white"
            />
          </div>
          <div className="flex items-end">
            {isEditMode ? (
              <>
                <Button
                  text="Cancel"
                  className="px-4 h-10 mr-2"
                  action={onCancel}
                />
                <Button
                  text="Save"
                  disabled={!label || !value}
                  className="px-4 h-10"
                  action={handleSave}
                />
              </>
            ) : (
              <Button
                text="Add"
                disabled={!label || !value}
                className="w-full h-10 flex items-center justify-center"
                action={handleSave}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
