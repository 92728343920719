import { useState } from 'react';
import { Button } from 'components/lib';
import { DeleteConfirmationDialog } from './deleteConfirmationDialog';
import { EnvironmentForm } from './environmentForm';
import { EditingMenu } from './editMenu';
import { useQuery } from '@tanstack/react-query';
import { getOrganizationEnvMembers } from '../../../api/organizations';
import { updateUser } from '../../../api/user';

export function OptionsEditor(props) {
  const { options, onChange, name } = props;
  const [updatedOptions, setUpdatedOptions] = useState(options);
  const [editingIndex, setEditingIndex] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [activeMenuIndex, setActiveMenuIndex] = useState(null);

  const { data: orgEnvMembers, refetch: refetchOrgEnviornments } = useQuery({
    queryKey: ['environmentMembers'],
    queryFn: getOrganizationEnvMembers,
  });

  const formatValue = (val) =>
    val.map(({ label, value, isDefault }, i) => ({
      name: label,
      baseUrl: value,
      isDefault: isDefault,
    }));

  const handleAddOption = (newOption) => {
    const newOptions = [newOption, ...updatedOptions];
    setUpdatedOptions(newOptions);
    onChange?.(name, formatValue(newOptions), undefined);
  };

  const handleGlobalClick = () => {
    setActiveMenuIndex(null);
  };

  const toggleMenu = (index) => {
    setActiveMenuIndex(activeMenuIndex === index ? null : index);
  };

  const handleEditClick = (index) => {
    setEditingIndex(index);
    setActiveMenuIndex(null);
  };

  const handleCancelEdit = () => {
    setEditingIndex(null);
  };

  const handleSaveEdit = (editedEnvironment) => {
    const updatedOptionsList = updatedOptions.map((opt, index) =>
      index === editingIndex ? editedEnvironment : opt,
    );
    setUpdatedOptions(updatedOptionsList);
    onChange?.(name, formatValue(updatedOptionsList), true);
    setEditingIndex(null);
  };

  const userCount = (opt) => {
    const currentUrl = opt.value;
    return orgEnvMembers?.[currentUrl]?.length || 0;
  };

  const handleDeleteClick = (indexToDelete, optionToDelete) => {
    const envUsersCount = userCount(optionToDelete);

    if (envUsersCount === 0) {
      handleRemoveOption(indexToDelete);
    } else {
      setDeleteConfirmation({
        index: indexToDelete,
        envName: optionToDelete.label,
        usersCount: envUsersCount,
      });
    }

    setActiveMenuIndex(null);
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmation(null);
  };

  const handleRemoveOption = async (indexToDelete, newEnvironmentUrl) => {
    const deletedOption = updatedOptions[indexToDelete];

    if (newEnvironmentUrl) {
      const usersToUpdate = orgEnvMembers[deletedOption.value] || [];

      try {
        await Promise.all(
          usersToUpdate.map((user) =>
            updateUser({
              attributes: {
                baseUrl: newEnvironmentUrl,
              },
              user_id: user,
            }),
          ),
        );

        await refetchOrgEnviornments();
      } catch (error) {
        console.error('Failed to update user environments:', error);
        return;
      }
    }

    // remove the options from the updatedOptions list
    const updatedOptionsList = updatedOptions.filter(
      (_, index) => index !== indexToDelete,
    );

    setUpdatedOptions(updatedOptionsList);
    onChange?.(name, formatValue(updatedOptionsList), undefined);
    setDeleteConfirmation(null);
  };

  const handleSetDefault = (newDefault) => {
    const updatedOptionsList = updatedOptions.map((opt) => ({
      ...opt,
      isDefault: opt.value === newDefault.value,
    }));
    setUpdatedOptions(updatedOptionsList);
    onChange?.(name, formatValue(updatedOptionsList), true);
    setActiveMenuIndex(null);
  };

  const CurrentEnvironmentInfo = ({ opt }) => (
    <>
      <div className="text-ellipsis overflow-hidden whitespace-nowrap">
        {opt.label}
      </div>
      <div className="text-ellipsis overflow-hidden whitespace-nowrap">
        {opt.value}
      </div>
      <div className="text-center px-4">{userCount(opt)} users</div>
      <div className="text-center">{opt.isDefault ? 'Default' : ''}</div>
    </>
  );

  const CurrentEnvironmentMenu = ({ opt, index }) => (
    <div className="relative flex justify-end">
      <Button
        icon="more-vertical"
        action={(e) => toggleMenu(index, e)}
        className="h-8 w-8 text-gray-400 hover:text-white relative z-210"
      />
      {activeMenuIndex === index && (
        <EditingMenu
          onEdit={() => handleEditClick(index)}
          onSetDefault={() => handleSetDefault(opt)}
          onDelete={() => handleDeleteClick(index, opt)}
        />
      )}
    </div>
  );

  return (
    <div
      className="flex flex-col h-full overflow-visible"
      onClick={handleGlobalClick}
    >
      <div className="flex-shrink-0 mb-4">
        <EnvironmentForm onSave={handleAddOption} envName={`${name}.new`} />

        <h4 className="text-lg font-semibold mb-2">Current environments</h4>

        <div className="flex flex-col h-[300px] overflow-y-auto">
          <div className="w-full">
            {updatedOptions?.map((opt, index) => (
              <div
                key={index}
                className={`w-[863px] ${
                  editingIndex === index ? 'h-[101px]' : 'h-16'
                } border rounded-lg overflow-visible mb-2 transition-all duration-300 ease-in-out`}
              >
                {editingIndex === index ? (
                  <EnvironmentForm
                    environment={opt}
                    onSave={handleSaveEdit}
                    onCancel={handleCancelEdit}
                    envName={`${name}.${index}`}
                    isEditMode={true}
                  />
                ) : (
                  <div className="grid grid-cols-[2fr,3fr,1fr,1fr,auto] items-center py-4 px-8 -mb-2">
                    <CurrentEnvironmentInfo opt={opt} />
                    <CurrentEnvironmentMenu opt={opt} index={index} />
                    {deleteConfirmation &&
                      deleteConfirmation.index === index && (
                        <DeleteConfirmationDialog
                          envIndex={deleteConfirmation.index}
                          envName={deleteConfirmation.envName}
                          usersCount={deleteConfirmation.usersCount}
                          defaultEnvName={
                            updatedOptions.find((o) => o.isDefault)?.label
                          }
                          environments={updatedOptions}
                          onCancel={handleDeleteCancel}
                          onConfirm={(newEnvironmentUrl) =>
                            handleRemoveOption(
                              deleteConfirmation.index,
                              newEnvironmentUrl,
                            )
                          }
                        />
                      )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
