import { useMutation, useQuery } from '@tanstack/react-query';
import { apiService, ENDPOINTS } from 'api';
import {
  getOrganization,
  getOrganizationEnvMembers,
  updateOrganization,
} from 'api/organizations';
import { events, mixpanelService } from 'app/analytics';
import { useCallback, useContext, useState } from 'react';
import { openUrl } from 'utils';
import useApiCall from './useApiCall';
import { ViewContext } from 'components/lib';

export const useOrganization = () => {
  const context = useContext(ViewContext);
  const { makeApiCall, isLoading } = useApiCall();

  const { data: organization, refetch: refetchOrganization } = useQuery({
    queryKey: ['organization'],
    queryFn: getOrganization,
  });

  const handleCreateStripeSession = useCallback(async () => {
    mixpanelService.trackEvent(events.MANAGE_STRIPE_ORG_CLICK);
    const endpoint = ENDPOINTS.ORG_CREATE_CUSTOMER_PORTAL_SESSION;
    const requestData = {
      redirect_url: window.location.origin,
    };

    try {
      const data = await makeApiCall(endpoint, 'post', requestData);
      if (data.portal_url) openUrl(data.portal_url);
    } catch (err) {
      context?.current && context.current.handleError(err);
    }
  }, [makeApiCall]);

  const handleEnvironmentFormSubmit = async (formData) => {
    try {
      let options = formData.baseUrlOptions;

      if (!Array.isArray(options) && options?.options) {
        options = options.options;
      }
      if (!Array.isArray(options)) {
        console.warn(
          'No valid options received, using current organization options',
        );
        options = organization?.baseUrlOptions || [];
      }

      let newDefaultUrl;
      const processedOptions = options.map(({ baseUrl, isDefault, name }) => {
        if (isDefault) {
          newDefaultUrl = baseUrl;
        }
        return { name, baseUrl };
      });

      const areOptionsEqual = (options1, options2) => {
        if (options1.length !== options2.length) return false;
        return options1.every(
          (option, index) =>
            option.name === options2[index].name &&
            option.baseUrl === options2[index].baseUrl,
        );
      };

      const hasOptionsChanged = !areOptionsEqual(
        processedOptions,
        organization.baseUrlOptions,
      );

      if (hasOptionsChanged) {
        await updateOrganization({ baseUrlOptions: processedOptions });
      }

      if (newDefaultUrl !== organization.baseUrl) {
        await updateOrganization({ baseUrl: newDefaultUrl });
      }

      if (
        hasOptionsChanged ||
        (newDefaultUrl && newDefaultUrl !== organization.baseUrl)
      ) {
        await refetchOrganization();
      }

      context.modal.hide();
      return true;
    } catch (error) {
      context.handleError(error);
      return false;
    }
  };

  const manageEnvs = () => {
    mixpanelService.trackEvent(events.ORG_MANAGE_ENV_CLICK);
    const orgBaseUrl = organization?.baseUrl;
    const options = organization?.baseUrlOptions?.map(({ name, baseUrl }) => ({
      label: name,
      value: baseUrl,
      isDefault: baseUrl === orgBaseUrl,
    }));

    context.modal.show({
      title: 'Manage environments',
      form: {
        baseUrlOptions: {
          type: 'optionsEditor',
          options: options,
        },
      },
      buttonText: 'Save',
      customSize: { width: '900px', height: '500px' },
      onSubmit: handleEnvironmentFormSubmit,
    });
  };

  return {
    organization,
    refetchOrganization,
    handleCreateStripeSession,
    manageEnvs,
  };
};
