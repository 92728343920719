import { Button } from '../../button/button';
import { useState } from 'react';

export const DeleteConfirmationDialog = ({
  envName,
  usersCount,
  defaultEnvName,
  environments,
  onCancel,
  onConfirm,
}) => {
  const [selectedEnv, setSelectedEnv] = useState('');

  const handleSelectChange = (e) => {
    setSelectedEnv(e.target.value);
  };

  const handleConfirm = () => {
    if (selectedEnv) {
      onConfirm(selectedEnv);
    }
    onCancel();
  };

  const availableEnvironments = environments.filter(
    (env) => env.label !== envName,
  );

  const isDefaultEnv = envName === defaultEnvName;

  if (isDefaultEnv) {
    return (
      <div className="bg-black bg-opacity-80 fixed inset-0 flex items-center justify-center z-50">
        <div className="dark:bg-zinc-800 bg-opacity-100 p-6 rounded-lg shadow-xl max-w-md w-full">
          <h2 className="text-xl font-bold mb-4">
            Cannot Delete Default Environment
          </h2>
          <p className="mb-4">
            The default environment cannot be deleted. Please set another
            environment as default before attempting to delete this one.
          </p>
          <Button
            text="Close"
            action={onCancel}
            className="h-8 px-3 text-sm whitespace-nowrap"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="bg-black bg-opacity-80 fixed inset-0 flex items-center justify-center z-50">
      <div className="dark:bg-zinc-800 bg-opacity-100 p-6 rounded-lg shadow-xl max-w-md w-full">
        <h2 className="text-xl font-bold mb-4">
          Delete '{envName}' environment
        </h2>
        <p className="mb-4">
          There are {usersCount} users using this environment. Which environment
          would you like to move them to?
        </p>

        {availableEnvironments.length > 0 ? (
          <>
            <select
              value={selectedEnv}
              onChange={handleSelectChange}
              className="w-full p-2 mb-4 dark:bg-zinc-800 border border-gray-600 rounded"
            >
              <option value="">Select an environment</option>
              {availableEnvironments.map((env) => (
                <option key={env.value} value={env.value}>
                  {env.label}
                  {env.label === defaultEnvName ? ' - Default' : ''}
                </option>
              ))}
            </select>

            <div className="flex justify-end space-x-2">
              <Button
                text="Cancel"
                action={onCancel}
                className="h-8 px-3 text-sm whitespace-nowrap"
              />
              <Button
                text="Confirm and Delete"
                action={handleConfirm}
                disabled={!selectedEnv}
                className="h-8 px-3 text-sm whitespace-nowrap"
              />
            </div>
          </>
        ) : (
          <div>
            <p className="mb-4">
              There are no other environments available to move users to.
            </p>
            <Button
              text="Close"
              action={onCancel}
              className="h-8 px-3 text-sm whitespace-nowrap"
            />
          </div>
        )}
      </div>
    </div>
  );
};
