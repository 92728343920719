import React from 'react';
import { Button, TitleRow } from 'components/lib';
import { OrgTitle } from './orgTitle';
import { ManageOrgDropdown } from '../manageOrgDropdown';

export const OrganizationHeader = ({
  organization,
  handleCreateStripeSession,
  addTeam,
  data,
  inviteMembersToOrg,
}) => (
  <TitleRow className={'my-5'} title={<OrgTitle organization={organization} />}>
    <div className="flex gap-2">
      <ManageOrgDropdown
        createTeam={() =>
          addTeam({
            adminOptions: data?.users?.map((member) => ({
              label: member.name,
              value: member.id,
            })),
          })
        }
      />
      <Button
        small
        text="See invoices"
        action={() => handleCreateStripeSession()}
      />
      <Button small text="Invite Members" action={inviteMembersToOrg} />
    </div>
  </TitleRow>
);
