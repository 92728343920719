import { Badge } from '../../../components/badge/badge';
import React from 'react';
import { useOrganization } from '../../../components/hooks/useOrganization';

export const OrgTitle = ({ organization }) => {
  return (
    <div className="flex items-center gap-3">
      <span>Organization</span>
      <Badge
        text={
          organization?.licenses > 0
            ? `${organization?.allowed_users}/${organization?.licenses} LICENSES`
            : `${organization?.allowed_users} USERS`
        }
        className="bg-gray-500 border-gray-500"
      />
    </div>
  );
};
