import { isEmpty } from 'lodash';
import { Dropdown } from '../../../components/dropdown/dropdown';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import React from 'react';

export const Environment = ({ organization, user, updateUser }) => {
  const options = organization?.baseUrlOptions;
  if (isEmpty(options)) return null;

  const currentOption = options.find(
    (option) => option.baseUrl === user?.baseUrl,
  );

  return (
    <div>
      <Dropdown>
        <Dropdown.Trigger>
          <div className="flex items-center gap-1 cursor-pointer text-brand1-300">
            <span>{currentOption?.name ?? user?.baseUrl}</span>
            <ChevronDownIcon />
          </div>
        </Dropdown.Trigger>
        <Dropdown.Menu>
          {options.map((option) => (
            <Dropdown.MenuItem
              key={`Environment-${option.name}`}
              onSelect={() =>
                updateUser({
                  attributes: { baseUrl: option.baseUrl },
                  user_id: user.id,
                })
              }
            >
              <div>
                <div className="font-semibold">{option.name}</div>
                <div className="text-gray-500">{option.baseUrl}</div>
              </div>
            </Dropdown.MenuItem>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
